<template>
    <div :class="'step-'+step_name">
        <!-- -------------------------------------------------- PLACEHOLDER -->
        <v-container class='filter'>
            <v-fade-transition>
                <div
                    v-if="step == 0"
                    transition="fade-transition"
                >
                    <h2 class="secondary--text">{{$t('product_searcher.lookingfor')}}</h2>
                    <v-row>
                        <!-- -------------------------------------------------- TYPES -->
                        <v-col>
                            <v-combobox
                                background-color="#fff"
                                outlined
                                :label="$t('product_searcher.producttype')"
                                :items="types"
                                item-text="label"
                                item-value="id"
                                multiple
                                v-model="filters.types"
                            ></v-combobox>
                        </v-col>

                    </v-row>
                    <!-- -------------------------------------------------- TIMES -->
                    <v-row>
                        <!-- <v-col
                            cols="12"
                            sm="6"
                        >
                            <h2 class="secondary--text">pour réserver entre le</h2>
                            <time-selector
                                background-color="#fff"
                                label="date de début"
                                v-model="filters.datetimes[0]"
                                :minutes_step="60"
                                :given_allowed_hours="[6,14]"
                            ></time-selector>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            :style="$is_mobile ? 'margin-top:-50px' : ''"
                        >
                            <h2 class="secondary--text">et le</h2>
                            <time-selector
                                :disabled="!filters.datetimes[0]"
                                :start_date="new Date(filters.datetimes[0])"
                                background-color="#fff"
                                label="date de fin"
                                v-model="filters.datetimes[1]"
                                :minutes_step="60"
                                :given_allowed_hours="[13,20]"
                            ></time-selector>
                        </v-col> -->
                        <v-col>
                            <h2 class="secondary--text">{{$t('product_searcher.fordates')}}</h2>
                            <time-filter
                                v-model="filters.datetimes"
                                background-color="#fff"
                                :label="$t('product_searcher.bookingdates')"
                                :time_mode="time_mode"
                            ></time-filter>
                        </v-col>
                    </v-row>
                    <v-row v-if="filters_ready">
                        <v-col>
                            <v-btn
                                @click="reload_from_filter(filters)"
                                block
                                color="primary"
                                large
                            >{{$t('product_searcher.search')}}</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-fade-transition>
            <v-slide-y-transition>
                <v-row v-if="step > 0">
                    <v-col @click="step_name='filters'">
                        <v-text-field
                            :value="sumup_filters"
                            disabled
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-slide-y-transition>
        </v-container>
        <v-container>
            <!-- -------------------------------------------------- AREA  / PRODUCTS -->
            <v-row v-if="step == 2">
                <v-col>
                    <v-card-title>{{selected_area.label}}</v-card-title>
                    <v-card-subtitle>{{selected_area.address}}</v-card-subtitle>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    :sm="map_cols"
                    cols="12"
                    class='smooth_col'
                >
                    <!-- ---------------------------------- MAP -->
                    <v-slide-y-reverse-transition>
                        <h1
                            class='watermark'
                            v-if="no_possibilities"
                        >{{$t('product_searcher.notpossible')}}</h1>
                    </v-slide-y-reverse-transition>
                    <div
                        :class='[no_possibilities ? "disabled" : "" ,"map_container"]'
                        ref='map_container'
                        :style="{width:'100%',height:map_height+'px'}"
                    >
                        <area-map
                            class='area_map'
                            elevtion='10'
                            ref='map'
                            :areas="areas"
                            :my_turn="step_name=='areas'"
                            @selected="select_area"
                            @cancel='step_name = "areas";selected_area=null'
                        ></area-map>
                    </div>
                </v-col>
                <v-col
                    sm=""
                    cols="12"
                >
                    <!-- ---------------------------------- PRODUCT LIST -->
                    <component :is="$is_mobile ? 'v-slide-y-reverse-transition':'v-slide-x-reverse-transition'">
                        <div v-if="step == 2">
                            <product-list
                                :selected_area="selected_area"
                                :selected_product_checker="product_is_in_cart"
                                :filter_dates="filters.datetimes"
                                @select="select_product"
                            ></product-list>
                        </div>
                    </component>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AreaMap from '@/components/area-map.vue'
import timeFilter from '@/components/time-filter.vue'
import { VSlideYReverseTransition, VSlideXReverseTransition } from 'vuetify/lib/components/transitions'

import { format } from 'date-fns'
import { v4 as uuid } from 'uuid'

import { mapActions, mapGetters } from 'vuex'
import ProductWithoutlist from "@/components/product-withoutlist";
import ProductList from "@/components/product-list.vue";

/*
@desc Product search Main Component (filter search, select on map, view products, add to cart). This component uses search steps to guide the user into looking for what it's looking for
*/
export default {
    components: { AreaMap, ProductList, timeFilter, VSlideYReverseTransition, VSlideXReverseTransition },
    data: () => ({
        filters: {
            types: [],
            datetimes: ['', ''], // DEV
        },

        types: [],

        areas: {},

        selected_area: null,

        all_steps: ['filters', 'areas', 'products'],
        step_name: 'filters',

        map_height: 0,
        map_cols: 12,
    }),

    watch: {
        /*
        @desc Reload map view when filters are updated
        */
        filters: {
            handler(filters) {
                this.reload_from_filter(filters)
            },
            deep: true,
        },
        'filters.types'() {
            this.check_filter_types()
        },
        /*
        @desc Change back Illustrations and recompute map size depending on the search step
        */
        step: {
            handler() {
                this.set_back_state(this.step_name)
                setTimeout(() => this.compute_map_size(), 500)
            },
            immediate: true
        },
        areas: {
            handler() {
                if (this.available_areas.length == 1 && this.step == 1) {
                    const area = this.available_areas[0]
                    const { lat, lng } = area
                    // this.select_area(area)
                    setTimeout(() => {
                        this.$refs.map.select_marker({ area_id: area.id, geo: { lat, lng } })
                    }, 100)
                }
            },
            deep: true
        },
    },

    computed: {
        /*
        @desc Time mode as specified in varenv (or hours default)
        */
        time_mode() {
            return process.env.VUE_APP_TIME_MODE ?? 'hours'
        },
        /*
        @desc When no product is available for the given filters
        */
        no_possibilities() {
            return this.step_name == 'areas' && this.available_areas.length == 0
        },
        /*
        @desc Current step ID
        */
        step() {
            return this.all_steps.indexOf(this.step_name)
        },
        /*
        @desc Dense version of filters
        */
        sumup_filters() {
            const { datetimes: [start, end] } = this.filters
            if (!start || !end) return 'no filters'
            const start_str = format(start, this.$t('product_searcher.datetimeformat'))
            const end_str = format(end, this.$t('product_searcher.datetimeformat'))
            return `${start_str} - ${end_str}`

        },
        /*
        @desc Are all filters sets to be able to load the filtered map
        */
        filters_ready() {
            return this.filters.datetimes.reduce((valid, date) => date ? valid : false, true)
        },
        available_areas() {
            return Object.values(this.areas)
                .filter(area => area.activated && Object.values(area.products).reduce((a, b) => a || (b.activated && b.available), false))
        },
        none_research() {
          return this.$t('product_searcher.allproducts')
        },
        ...mapGetters(['cart_hash_map'])
    },

    methods: {
        ...mapActions(['add_to_cart', 'set_back_state', 'update_cart_with']),
        compute_map_size() {
            const win_height = window.innerHeight
            const top = this.$refs.map_container?.offsetTop
            const max_height = win_height - top - 10
            this.map_height = this.$is_mobile ?
                {
                    0: 0,
                    1: max_height,
                    2: 100,
                }[this.step] ?? 100
                :
                {
                    0: 0,
                    1: max_height,
                    2: max_height,
                }[this.step] ?? 100
            this.map_cols = !this.$is_mobile ? {
                0: 0,
                1: 12,
                2: 4,
            }[this.step] ?? 12 : 12
            setTimeout(() => this.$refs.map.$refs.map.mapObject.invalidateSize(), 300)
        },
        next_step() {
            this.step_name = this.all_steps[(this.step + 1) % this.all_steps.length]
        },
        select_area(area) {
            this.selected_area = area
            this.step_name = 'products'
        },
        product_to_item(product) {
            return {
                id: uuid(),
                start_date: this.filters.datetimes[0].getTime() / 1000,
                end_date: this.filters.datetimes[1].getTime() / 1000,
                product_id: product.id,
                product
            }
        },
        item_hash(item) {
            const { product_id, start_date, end_date } = item
            return product_id + start_date + end_date
        },
        select_product(product) {
            this.update_cart_with(this.product_to_item(product))
        },
        product_is_in_cart(product) {
            return !!this.cart_hash_map[this.item_hash(this.product_to_item(product))]
        },
        /*
        @desc Reload map data from filters configuration
        */
        async reload_from_filter({ types, datetimes: [start_datetime, end_datetime] }) {

            this.$set(this, 'areas', {})

            if (!start_datetime || !end_datetime) return this.step_name = 'filters'

            const start = parseInt(start_datetime.getTime() / 1000)
            const end = parseInt(end_datetime.getTime() / 1000)
            const type_ids = types.filter(e => e != this.none_research).map(type => type.id)

            const found_areas = await this.$api.user.filters.areas(type_ids.join(','), start, end)
            this.$set(this, 'areas', found_areas)
            this.step_name = 'areas'
        },
        /*
        @desc Load product types
        */
        async reload_types() {
            const found_types = await this.$api.user.filters.types()
            this.$set(this, 'types', found_types)
        },
        check_filter_types() {
            if (this.filters.types.length == 0) {
                this.filters.types.push(this.none_research)
            } else if (this.filters.types.length > 1 && this.filters.types.includes(this.none_research)) {
                this.filters.types.splice(0, 1)
            }
        }

    },
    /*
    @desc Auto load types and compute init map size
    */
    mounted() {
        this.reload_types()
        setTimeout(() => this.compute_map_size(), 500)
        this.check_filter_types()
        this.set_back_state(this.step_name)
    }

}
</script>

<style scoped>
.filter {
    max-width: 100%;
    width: 700px !important;
    transition: all 0.5s;
}
.map_container {
    transition: height 0.2s;
}
.map_container.disabled {
    opacity: 0.3;
    pointer-events: none;
}
.smooth_col {
    transition: all 0.2s;
}
.watermark {
    opacity: 0.6;
    font-size: 40px;
    top: 55% !important;
    width: calc(100% - 40px) !important;
    margin-left: 20px;
    margin-right: 20px;
}
.v-text-field {
    margin-bottom: -20px;
}
h2 {
    margin-bottom: 10px;
}
</style>