<template >
    <div
        :class='["area_map",my_turn ? "":"disabled"]'
        @click="clicked_map"
    >
        <l-map
            class='map'
            ref='map'
            :zoom="zoom"
            :center="center"
        >
            <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                class='tt'
            >
            </l-tile-layer>
            <!-- <l-tile-layer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                class='tt'
            >
            </l-tile-layer> -->
            <span
                v-for="marker in markers"
                :key="marker.area_id"
            >
                <l-marker
                    v-for="(url, index) in marker.icons"
                    :key="index"
                    :lat-lng="marker.geo"
                    :icon="get_icon(url)"
                    @click="marker.activated ? select_marker(marker) : null"
                    @l-add="$event.target.openPopup()"
                >
                    <l-tooltip
                        v-if="marker.count && marker.activated"
                        :content="$tc('area_map.markercount', marker.count)"
                        :options="{ permanent: true, direction:'top' }"
                    ></l-tooltip>
                </l-marker>
            </span>
        </l-map>
    </div>
</template>

<script>

import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet"
import { icon } from 'leaflet'

/*
@desc Reservation Areas Map
*/
export default {
    components: { LMap, LTileLayer, LMarker, LPopup, LTooltip },

    props: ['areas', 'my_turn'],

    data: () => ({
        center: { lat: 43.59966671275715, lng: 1.4404591546122683 },
        zoom: 13,
        icons_map: {},
        selected: null
    }),

    computed: {
        /*
        @desc Create areas markers (geoloc + icon + available items count)
        */
        markers() {
            return Object.values(this.areas).map(({ id, lat, lng, products, activated }) => {
                const av_products = Object.values(products)
                    .filter(product => product.activated !== false && product.available !== false)
                const is_activated = activated && av_products.length > 0

                return {
                    count: is_activated ? ((av_products.length || '') + '') : '',
                    geo: { lat: lat, lng: lng },
                    icons: [is_activated ? '/images/pins/default.png' : '/images/pins/default_fade.png'],
                    activated: is_activated,
                    area_id: id
                }
            })
        },
        /*
        @desc Is there a merker selected
        */
        marker_selected() {
            return this.selected
        }
    },

    methods: {
        /*
        @desc A Marker was selected
        */
        clicked_map() {
            if (!this.selected) return
            this.$emit('cancel')
            this.selected = null
        },
        /*
        @desc Get singleton url object
        @arg1 image url
        */
        get_icon(url) {
            if (!this.icons_map[url]) {
                this.icons_map[url] = icon({
                    iconUrl: url,
                    iconSize: [64, 64],
                    iconAnchor: [16, 37]
                })
            }
            setTimeout(() => {
                this.icons_map[url].options.iconSize = [100, 100]
                this.icons_map[url].createIcon()
            }, 1000)
            return this.icons_map[url]
        },
        /*
        @desc Selecte specific marker
        */
        select_marker(marker) {
            const area = this.areas[marker.area_id]
            this.$emit('selected', area)
            setTimeout(() => {
                this.$refs.map.mapObject.invalidateSize()
                this.zoom = 17
                setTimeout(() => {
                    this.center = marker.geo
                }, 300)
                this.selected = marker
            }, 200)
        },
        /*
        @desc Center map according to user's geoloc (acquired through `navigator.geolocation`)
        */
        load_center() {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
                    const center = { lat: 43.59966671275715, lng: 1.4404591546122683 }//{ lat: latitude, lng: longitude }
                    this.center = center
                })
            }
        },

        set_center(center) {
            this.center = center
            this.zoom = 13
        }
    },
    /*
    @desc Auto load gps center
    */
    mounted() {
        this.load_center()
    }
}
</script>

<style>
.leaflet-grab {
    cursor: default !important;
}
.leaflet-control-container {
    display: none;
}
.area_map {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}
/* .area_map.disabled {
    filter: saturate(50%);
    opacity: 0.5;
} */
.area_map.disabled .map {
    pointer-events: none;
}
.leaflet-pane {
    z-index: 0 !important;
}
.deactivated {
    opacity: 0.5;
}
.disabled .leaflet-tile-pane {
    filter: saturate(50%);
    opacity: 0.5;
}
</style>
