import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg'
    },
    theme: {
        // dark: true,
        themes: {
            light: {
                primary: '#96c11e',
                secondary: '#96c11e',
                accent: '#FFA69E',
                background: '#FFFFFF',
                error: '#FF686B',
                info: '#3DA470',
                success: '#96c11e',
                warning: '#FF686B',
            },
            dark: {
                primary: '#96c11e',
                secondary: '#FFFFFF',
                accent: '#FFA69E',
                background: '#121212',
                error: '#FF686B',
                info: '#F8E16C',
                success: '#96c11e',
                warning: '#FF686B',
            }
        },
        options: { customProperties: true },
    },
})
